import * as React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

const MovingHomePage = () => (
    <Layout>
        <Seo title="Got a secured loan from Nemo and thinking of moving house" description={'Thinking of moving house whilst you have a secured loan from Nemo? For important information on what you need to do before you move house visit us online today.'} lang={'en-gb'} />
        <div className="threeCol purple cfx">
            <aside id="leftCol">
                <div id="tNav">
                    <ul>
                        <li><a href="/customer-service/moving-home" className={'selected'}>How your Nemo mortgage
                            works</a></li>
                        <li><a href="/customer-service/remortgaging">What to do if you want to
                            remortgage </a></li>
                        <li><a href="/customer-service/settle-my-second-charge-mortgage">Settle
                            my Nemo mortgage early</a></li>
                        <li><a href="/customer-service/update-my-details">Update my details</a>
                        </li>
                    </ul>
                </div>

            </aside>
            <section id="rightCol">
                <div className="contCol">
                    <div className="contColInr">
                        <div className="bubble purple cfx">
                            <div className="inr">
                                <h1>How your Nemo mortgage works</h1>
                            </div>
                            <div className="bot"></div>
                        </div>
                        <p className="leading"></p>
                        <p>
                            <strong>What does my contractual monthly repayment include?</strong> <br /><br />Contractual monthly
                            repayments consist of an interest and a capital balance payment. The capital balance payment
                            reduces your mortgage balance every month. By paying your contractual monthly repayment in full
                            and on time you will clear the whole balance by the end of the term, provided that the term has
                            not changed since you started your mortgage.<br /><br />Interest is accrued daily, similar to most
                                mortgage products and is in accordance with your Terms and Conditions.<br /><br /><strong>Why is
                                    my balance not reducing at the same rate as the monthly payments I’m
                                    making?<br /></strong><br />Your monthly repayment consists of two parts: an amount to pay
                                    towards your interest and an amount towards your capital balance. The interest is
                                    accrued daily, and is applied every month to your Nemo second charge mortgage. Because
                                    this interest is calculated on the outstanding balance, the proportion of interest that
                                    you pay as part of your monthly payment is greater at the start of your mortgage. As the
                                    balance reduces, so does the proportion of the contractual monthly repayment that
                                    relates to interest. So, as time passes the balance will begin to reduce more
                                    quickly.&nbsp;<br /><br /><strong>Why is there an outstanding balance on my account at the
                                        end of the contractual term? <br /></strong><br />During the term of your mortgage, you
                                        may have incurred fees and/or additional interest. An example of when a fee or
                                        additional interest can be incurred if you have ever fallen behind with your
                                        contractual monthly repayment. This type of fee is sometimes called a Default Fee
                                        and does not incur interest. Fees for different types of services, for example a
                                        Deed of Postponement, can also be incurred and if these are added to your account,
                                        they will have incurred interest. These are referred to in our Tariff of Fees and
                                        Charges as Service Fees.&nbsp;<br />&nbsp; <br />If these fees aren’t paid by you, these
                                            will remain outstanding at the end of your term.<br /><br />Our up to date Tariff of
                                                Fees and Charges can be found <a href="/our-fees">here</a>. There might
                                                be other fees in your Terms and Conditions though so please check this
                                                source too for more information.&nbsp;<br /><br />Other factors can cause
                                                    additional interest to be accrued, such as changing a payment date. If
                                                    this additional interest isn’t paid by you beforehand, it will remain
                                                    outstanding at the end of your term.&nbsp; <br /><br />If you want to pay
                                                        any fees or additional interest now, or to simply check if any are
                                                        outstanding, please <a href="/contact-us">contact
                                                            us</a>.<br /><br />Your Nemo second charge mortgage will only be
                                                            considered as fully settled once the outstanding balance is
                                                            fully repaid which includes any outstanding fees and
                                                            interest.&nbsp;&nbsp;&nbsp; <br /><br /><strong>When can Nemo change
                                                                my interest rate?</strong><br /><br />Nemo can adjust your
                                                                interest rate for various reasons which are set out in your
                                                                Mortgage Agreement. This is usually when Nemo's costs or
                                                                market conditions change.<br /><br />Your Terms and Conditions
                                                                    will indicate when we can vary the interest rate on your
                                                                    mortgage. Our rates are not tracked to any particular
                                                                    interest rate index.&nbsp; However, your Terms and
                                                                    Conditions might reference a specified rate index, like
                                                                    the Bank of England, to restrict how frequently and by
                                                                    how much we can vary your interest rate.<br />&nbsp;
                                                                        <br /><strong>Settlements, and settlement
                                                                            quotes</strong><br /><br />If you have a question
                                                                            about <a href="/customer-service/settle-my-second-charge-mortgage">how to settle your mortgage</a>, or wish to
                                                                            request a settlement quote, please click <a
                                                                                href="/faq">here</a>. <br />
                        </p>
                        <p className="telNum">
                        </p>
                        <p>
                            <br />
                        </p>
                    </div>
                </div>
                <div className="fbCol"></div>
            </section>
        </div>
    </Layout>
)

export default MovingHomePage;